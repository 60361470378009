import Vue from "vue";
import Vuex from "vuex";
import authFlowStoreTemplate from '../../Features/AuthorizationFlow/Store/store'
import { PromotionStatus } from "../Promotion/constants/constants.js";
import Settings from '../Settings/store/modules/Settings'
import { APIService } from './ApiService.js';
import { locationCategories, FacebookFlexibleSpecConst, FacebookTargetLocationConst } from '../CampaignLauncher/Constants/FacebookEnums.js'
import * as molocoEnums from "./Constants/MolocoEnums.js";
Vue.use(Vuex);

const initialState = () => {
  return {
    posts: [],
    businessProfilesSearchEngineId: 0,
    businessProfileId: 0,
    businessProfile: null,
    selectedFbObjective: null,
    accounts: [],
    accountAssetsMapper: {},
    campaignLauncherConfigurations: [],
    lineItemConfigurations: [],
    mediaPlanId: 0,
    mediaPlans: [],
    deltax: {},
    savedTargetsMapperByBpseId: {},
    campaignLauncherConfigBpseIdMapper: {},
    campaignLauncherConfigs: {},
    mediaPlanLineItems: [],
    selectedCampaignLauncherConfigId: 0,
    mappedLineItems: [],
    promoTargetClusters: [],
    imageIdUrlMap: {},
    builtImageIdUrlMap: false,
    showNextStep: false,
    facebookEntities: null,
    amsSdEntities: null,
    previewableSpec: [],
    publishedCreatives: {},
    creativesToPublish: {},
    adFormats: [],
    adsCountCreateTab: {},
    adsCountEditTab: {},
    adsCountPublishTab: {},
    publishedCreativesByCampaignConfigId: {},
    displayExistingSubmissions: false,
    isCreationFlow: false,
    creativeFields: [],
    isCreated: false,
    isSdCreated: false,
    usersData: {},
    publishedCampaignsCountByMediaPlan: {},
    labels: {},
    instantExperiences: [],
    showCampaignLauncherError: false,
    campaignLauncherErrorMessage: "",
    campaignLauncherAssetsWithError: [],
    loadingCampiagnLauncherAssets: false,
    showCampaignLauncherAlert: false,
    campaignLauncherAlertMessage: "",
    dxInboundEmails: [],
    s3InboundMailRecipientDomain: "",
    selectedCampaignLauncherConfig: {},
    existingPostAds: [],
    isOauthExpired: false,
    isPickerApiMounted: false,
    showSpinner: false,
    selectedPublisher: 5,
    googleAccounts: [],
    googleLanguages: [],
    facebookLanguages: [],
    adwordsEntitiesResponse: {},
    adwordsEnums: {
      campaignType: {
        "Search": 1,
        "App": 6,
        "Performance Max": 7
      },
      campaignSubType: {
        "App Installs": 8,
        "App Engagement": 10
      },
      biddingStrategyTypes: {
        1: "Manual",
        2: "Automatic",
        6: "Focus Impression",
        8: "Conversion Optimizer",
        9: "Target CPA",
        10: "Target ROAS"
      },
      appCampaignBiddingFocus: {
        "Install Volume": 1,
        "In-app actions": 2
      },
      appStore: {
        "Apple App Store": 2,
        "Google App Store": 3
      },
      targetUsers: {
        "All Users": 1,
        "Users likely to perform an in-App action": 2
      },
      status: {
        1: "Active",
        3: "Paused"
      },
      campaignGoals: {
        1: "Webite Visits",
        2: "Phone Calls",
        3: "App Installs",
        4: "Physical Visit"
      },
      campaignBudgetDelivery: {
        1: "Standard"
      },
      campaignBudgetPeriod: { // budgetType
        1: "Daily"
      },
      campaignNetworks: {
        5: "Search Network",
        2: "Google Display Network"
      },
      entityType: {
        "Campaign": 1,
        "AdGroup": 2,
        "Ad": 3
      }
    },
    isPromotionMode: false,
    promotionId: null,
    promotionObject: {},
    products: {},
    amsAccounts: [],
    amsEnums: {
      status: {
        "Active": 1,
        "Paused": 3
      },
      targetingTypeForProducts: {
        "Manual": 1,
        "Amazon Auto": 2
      },
      targetingTypeForBrands: {
        "DeltaX Smart": 1
      },
      targetingTypeForDisplay: {
        1: "Product Targeting",
        2: "Audience Targeting"
      },
      biddingStrategy: {
        "Dynamic Bid - Down Only": 1,
        "Dynamic Bid - Up And Down": 2,
        "Fixed Bids": 3
      },
      targetTypes: {
        "Clusters": 1,
        "ASINS": 2
      },
      scheduling: {
        "Run continuously starting today": 1,
        "Use Line item schedule": 2
      },
      campaignGrouping: {
        "One Campaign per Cluster": 1,
        "One Campaign for All Clusters": 2
      },
      publisherType: {
        1: "Sponserd Products",
        2: "Sponserd Brands",
        3: "Sponserd Display"
      },
      targetType: {
        Cluster: 1,
        ASIN: 2
      },
      campaignType: {
        1: "Sponsored Product",
        2: "Sponsored Brand",
        3: "Sponsored Display"
      },
      budgetType: {
        1: "Daily",
        2: "Life Time"
      },
      biddingOptimization: {
        1: "Page visits",
        2: "Conversions"
      },
      creativeCustomization: {
        0: "None",
        1: "Headline and logo",
        2: "Custom image"
      }
    },
    publisher: {
      Adwords: 1,
      Facebook: 5,
      AMS: {
        id: 10,
        SponsoredProducts: 11,
        SponsoredBrands: 12,
        SponsoredDisplay: 13
      },
      DV360: 9,
      LinkedIn: 7,
      Twitter: 6,
      Moloco: 45
    },
    publisherOptions: [
      { id: 5, name: "Facebook" },
      { id: 1, name: "Google Ads" },
      { id: 11, name: "AMS - Sponsored Products" },
      { id: 12, name: "AMS - Sponsored Brands" },
      { id: 13, name: "AMS - Sponsered Display" },
      { id: 9, name: "DV360" },
      { id: 7, name: "LinkedIn" },
      { id: 6, name: "Twitter" },
      { id: 45, name: "Moloco" }
    ],
    savedTargets: {},
    amsEntitiesResponse: {},
    selectedMediaPlan: {},
    bpCurrencySymbol: "",
    dbmAccounts: {},
    linkedInAccounts: {},
    twitterAccounts: {},
    dv360EntitiesResponse: {},
    dv360Targets: [],
    imagesData: [],
    videosData: [],
    showAdSheetLoader: false,
    accountMacros: {},
    publisherSupportedForSubmissions: [
      { id: 5, name: "Facebook" },
      { id: 1, name: "Google Ads" },
      { id: 11, name: "AMS - Sponsered Products" },
      { id: 12, name: "AMS - Sponsered Brands" },
      { id: 13, name: "AMS - Sponsered Display" },
      { id: 9, name: "DV360" },
      { id: 7, name: "LinkedIn" },
      { id: 6, name: "Twitter" },
      { id: 45, name: "Moloco" }
    ],
    engineTypes:
    {
      All: -1,
      Search: 1,
      Social: 2,
      Display: 3,
      Video: 4,
      Mobile: 5,
      Remarketing: 6,
      Affiliate: 7,
      Email: 8,
      Other: 9,
      PostBoost: 10,
      AMS: 11
    },
    clusterDetails: [],
    isSdConfigUpdated: false,
    // engineTypeId : [list of publisher Ids]
    engineType_PublisherMapper: {
      1: [1, 9],
      2: [5, 7, 6, 45],
      11: [10, 11, 12, 13]
    },
    isSpSubmissionFlow: false,
    autoNavigateToNextStep: false,
    twitterEntitiesResponse: {},
    summaryLocation: '',
    tableData: null,
    keywordTablesData: { keyword: [], negativeKeyword: [] },
    productTablesData: { asin: [], negativeAsin: [] },
    mediaPlanConfigs: [],
    selectedMediaplanName: "",
    configChangeLoading: false,
    exceptionLabels: [
      { family: "assetClaimRequiredException", label: `There are no claimed assets for the account. Please <a target="_blank" href='/App/BizProfiles/Channels/'>click here</a> claim the required assets and try again.` }
    ],
    connectedInstagramAccountId: 0,
    linkedInEntitiesResponse: {},
    dbStores: null,
    dbLabelTypes: null,
    adPublishEnabledPublisherIds: [5, 6, 7, 9, 10, 11, 13, 45],
    selectedObjectiveIdForPublisher: null,
    recipeType: 2,
    currentAdSheet: null,
    linkedInLeadForms: [],
    tempMappedLocations: {},
    savedMappedLocations: {},
    mappedLocationsChanged: 0,
    dbLocations: [],
    AmsLoadNext: false,
    AmsLoadSave: false,
    AmsDataValid: true,
    IsAmsLineItemsUpdated: false,
    AmsTableInvalidType: 1,
    isCampaignLauncherVerificationEnabled: true,
    placementCustomizationSettings: null,
    dynamicCreativeSettings: null,
    existingTweets: [],
    adsData: [],
    previewableRowsCreativeSpec: [],
    publishJson: [],
    nomenclatureSettings: [],
    hotInitalState: {},
    publishedCreativesInitialState: {},
    amsPublishedData: [],
    amsPublishedConfig: [],
    editSheetLoadProgress: 0,
    currentVersionOfGeneratedEntities: 0,
    labelDimensions: {},
    adLabelDimensionNomenclature: [],
    lineItemLabels: {},
    initialLocations: [],
    adwordsLocations: [],
    adwordsTargets: [],
    negativeKeywords: [],
    targetProductAudienceActions: [
      {
        action: "Added to cart",
        value: "AddToCart"
      },
      {
        action: "Viewed",
        value: "ViewContent"
      },
      {
        action: "Purchased",
        value: "Purchase"
      }
    ],
    adgroups: [],
    adGroupCampaignMapper: [],
    adGroupNameToIdentifierMapper: {},
    macroValueMapper: undefined,
    adgroupDetails: [],
    editedSummaryData: "",
    amsSheetDetails: [],
    allCreativesInfo: {},
    createSheetCreatives: {},
    editSheetCreatives: {},
    publishedSheetCreatives: {},
    totalSheetCreatives: {},
    adCreativeMapping: [],
    adsetBudgetError: {
      isAdsetBudgetInvalid: false,
      errorMessage: ''
    },
    changedData: {},
    adSheetCustomizationSettings: {},
    isPostsFetched: false,
    facebookPosts: [],
    instagramPosts: [],
    fetchingProductsetsFailed: {},
    failedTargets: {},
    isRefreshAmsTargets: false,
    isRefreshEntitiesData: false,
    conversionAction: [],
    isWizardFlow: false,
    publishedEditedSummaryData: "",
    mediaResponse: {},
    promoAlertMessages: [],
    bulkApplyCalledForLineItemId: 0,
    allStoresAlreadyFetched: false,
    facebookTargetMacroName: "Target-{Age}-{Gender}-{Location}",
    facebookTargetNameTemp: "",
    amsSpError: [],
    facebookTargetCreationData: [
      {
        "savedTargetId": null,
        "targetName": "",
        ...FacebookTargetLocationConst,
        ...FacebookFlexibleSpecConst,
        "isFavourite": false,
        "estimatedReach": 0,
        "lowerBoundEstimate": 0,
        "upperBoundEstimate": 0,
        "estimatedReachErrorMessage": ""
      }
    ],
    facebookTargetLocationData: [FacebookTargetLocationConst],
    facebookFlexibleSpecData: [JSON.parse(JSON.stringify(FacebookFlexibleSpecConst))],
    facebookTargetLoading: [false],
    selectedBpseId: 0,
    molocoAccounts: [],
    molocoCampaignLauncherPublishDataId: 0,
    molocoEntitiesResponse: {},
    molocoTargetingKeyValuePairs: [],
    isValidMolocoTarget: false,
    molocoImpressionCaps: [],
    molocoSelectedCustomAudiencesNames: [],
    molocoBrandApps: {},
    molocoTrackingLinks: {},
    publishInProgress: false,
    publishersSupportingNewLauncherFlow: [45],
    renderSubmissionSummary: false,
    targetsEstimateData: {},
    adwordsAdSheetSettingsInfo: {},
    extraPropertiesInTargets: {},
    showMolocoTargetModalInSummary: false,
    molocoLiveImpressionCaps: [],
    molocoLiveCustomAudiencesNames: [],
    molocoCustomTargets: [],
    molocoTargetingSelectedCondition: "AND",
    molocoTargetingLiveCondition: "AND",
    molocoEditedStatuses: [],
    molocoEditedBids: [],
    molocoEditedTargets: [],
    molocoEditedExternalAudiences: [],
    savedTargetsByBpseIdMapper: {},
    molocoEditedLandingUrls: [],
    isPromotionCampaignLaunch: false,
    uploadedInstagramVideos: [],
    dv360AdGroups: [],
    isSummaryDataSet: false,
    allAdExtensions: [],
    editedCreativesToPublish: {},
    fetchAdwordsEditTab: false,
    globalLabels: [],
    isSummaryDataFetchComplete: false,
    fetchAdwordsPublishTab: false,
    objectives: [],
    keyMetrics: [],
    editedCampaignBid: {},
    landingPageSettings: {},
    landingPages: null,
    invalidMapperScreenLineItems: [],
    selectedPromotionLauncherConfig: {},
    adSheetSettingsOpenModel: false
  }
}

const campaignLauncheStore = {
  state: initialState(),
  mutations: {
    set_AmsPublishedConfig (state, value) {
      state.amsPublishedConfig = value
    },
    set_AmsSpError (state, value) {
      state.amsSpError = value
    },
    set_isSpSubmissionFlow (state, value) {
      state.isSpSubmissionFlow = value
    },
    set_isSdConfigUpdated (state, value) {
      state.isSdConfigUpdated = value
    },
    set_clusterDetails (state, value) {
      state.clusterDetails = value;
    },
    set_failedTargets (state, value) {
      state.failedTargets = value;
    },
    set_AmsLoadNext (state, value) {
      state.AmsLoadNext = value
    },
    set_AmsLoadSave (state, value) {
      state.AmsLoadSave = value
    },
    set_AmsDataValid (state, value) {
      state.AmsDataValid = value
    },
    set_amsLineItemsUpdate (state, value) {
      state.IsAmsLineItemsUpdated = value;
    },
    set_AmsTableInvalidType (state, value) {
      state.AmsTableInvalidType = value
    },
    set_isRefreshAmsTargets (state, value) {
      state.isRefreshAmsTargets = value
    },
    set_isRefreshEntitiesData (state, value) {
      state.isRefreshEntitiesData = value
    },
    set_posts (state, posts) {
      state.posts = posts
    },
    SET_BPSEID (state, businessProfilesSearchEngineId) {
      state.businessProfilesSearchEngineId = businessProfilesSearchEngineId;
    },
    SET_BPID (state, businessProfileId) {
      state.businessProfileId = businessProfileId;
    },
    SET_selectedFbObjective (state, objectiveId) {
      state.selectedFbObjective = objectiveId
    },
    SET_imageIdUrlMap (state, value) {
      state.imageIdUrlMap = value;
    },
    set_builtImageIdUrlMap (state, flag) {
      state.builtImageIdUrlMap = flag
    },
    set_previewableSpec (state, spec) {
      state.previewableSpec = spec
    },
    set_accounts (state, accounts) {
      state.accounts = accounts;
    },
    set_accountAssetsMapper (state, accountAssetsMapper) {
      state.accountAssetsMapper = accountAssetsMapper
    },
    set_campaignLauncherConfigurations (state, campaignLauncherConfigurations) {
      state.campaignLauncherConfigurations = campaignLauncherConfigurations;
    },
    set_mediaPlanId (state, mediaPlanId) {
      state.mediaPlanId = mediaPlanId
    },
    set_deltax (state, deltax) {
      state.deltax = deltax;
    },
    set_savedTargetsMapperByBpseId (state, savedTargets) {
      state.savedTargetsMapperByBpseId = savedTargets;
    },
    set_campaignLauncherConfigBpseIdMapper (state, campaignLauncherConfigBpseIdMapper) {
      state.campaignLauncherConfigBpseIdMapper = campaignLauncherConfigBpseIdMapper;
    },
    set_mediaPlanLineItems (state, mediaPlanLineItems) {
      state.mediaPlanLineItems = mediaPlanLineItems;
    },
    set_mappedLineItems (state, mappedLineItems) {
      state.mappedLineItems = mappedLineItems;
    },
    set_promoTargetClusters (state, promoTargetClusters) {
      state.promoTargetClusters = promoTargetClusters;
    },
    set_campaignLauncherConfigs (state, campaignLauncherConfigs) {
      state.campaignLauncherConfigs = campaignLauncherConfigs;
    },
    SET_SHOW_PREVIEW (state, showNextStep) {
      state.showNextStep = showNextStep
    },
    SET_FACEBOOK_ENTITIES (state, facebookEntities) {
      state.facebookEntities = facebookEntities
    },
    Set_Ams_sd_entities (state, amsEntities) {
      state.amsSdEntities = amsEntities
    },
    set_selectedCampaignLauncherConfigId (state, campaignConfigId) {
      state.selectedCampaignLauncherConfigId = campaignConfigId;
    },
    set_publishedCreatives (state, newPublishCreatives) {
      state.publishedCreatives = newPublishCreatives
    },
    set_creativesToPublish (state, newCreativesToPublish) {
      state.creativesToPublish = newCreativesToPublish
    },
    set_editedCreativesToPublish (state, editedCreativesToPublish) {
      state.editedCreativesToPublish = editedCreativesToPublish
    },
    set_adFormats (state, adFormats) {
      state.adFormats = adFormats
    },
    set_adsCountCreateTab (state, adsCount) {
      state.adsCountCreateTab = adsCount
    },
    set_adsCountEditTab (state, adsCount) {
      state.adsCountEditTab = adsCount
    },
    set_adsCountPublishTab (state, adsCount) {
      state.adsCountPublishTab = adsCount
    },
    set_publishedCreativesByCampaignConfigId (state, publishedCreativesByCampaignConfigId) {
      state.publishedCreativesByCampaignConfigId = publishedCreativesByCampaignConfigId;
    },
    set_displayExistingSubmissions (state, displayExistingSubmissions) {
      state.displayExistingSubmissions = displayExistingSubmissions;
    },
    set_isCreationFlow (state, isCreationFlow) {
      state.isCreationFlow = isCreationFlow;
    },
    set_creativeFields (state, creativeFields) {
      state.creativeFields = creativeFields
    },
    set_isCreated (state, isCreated) {
      state.isCreated = isCreated;
    },
    set_isSdCreated (state, isSdCreated) {
      state.isSdCreated = isSdCreated;
    },
    set_usersData (state, usersData) {
      state.usersData = usersData
    },
    set_publishedCampaignsCountByMediaPlan (state, publishedCampaignsCountByMediaPlan) {
      state.publishedCampaignsCountByMediaPlan = publishedCampaignsCountByMediaPlan
    },
    set_labels (state, labels) {
      state.labels = labels
    },
    set_instantExperiences (state, instantExperiences) {
      state.instantExperiences = instantExperiences;
    },
    set_showCampaignLauncherError (state, showCampaignLauncherError) {
      state.showCampaignLauncherError = showCampaignLauncherError;
    },
    set_campaignLauncherErrorMessage (state, campaignLauncherErrorMessage) {
      state.campaignLauncherErrorMessage = campaignLauncherErrorMessage;
    },
    set_showCampaignLauncherAlert (state, showCampaignLauncherAlert) {
      state.showCampaignLauncherAlert = showCampaignLauncherAlert;
    },
    set_campaignLauncherAlertMessage (state, campaignLauncherAlertMessage) {
      state.campaignLauncherAlertMessage = campaignLauncherAlertMessage;
    },
    set_campaignLauncherAssetsWithError (state, campaignLauncherAssetsWithError) {
      state.campaignLauncherAssetsWithError = campaignLauncherAssetsWithError;
    },
    set_loadingCampiagnLauncherAssets (state, loadingCampiagnLauncherAssets) {
      state.loadingCampiagnLauncherAssets = loadingCampiagnLauncherAssets;
    },
    set_dxInboundEmails (state, dxInboundEmails) {
      state.dxInboundEmails = dxInboundEmails;
    },
    set_s3InboundMailRecipientDomain (state, s3InboundMailRecipientDomain) {
      state.s3InboundMailRecipientDomain = s3InboundMailRecipientDomain;
    },
    set_selectedCampaignLauncherConfig (state, config) {
      state.selectedCampaignLauncherConfig = config
    },
    set_existingPostAds (state, existingPostAds) {
      state.existingPostAds = existingPostAds
    },
    set_isOauthExpired (state, value) {
      state.isOauthExpired = value;
    },
    set_isPickerApiMounted (state, isPickerApiMounted) {
      state.isPickerApiMounted = isPickerApiMounted
    },
    set_showSpinner (state, showSpinner) {
      state.showSpinner = showSpinner
    },
    set_selectedPublisher (state, publisher) {
      state.selectedPublisher = publisher;
    },
    set_googleAccounts (state, googleAccounts) {
      state.googleAccounts = googleAccounts;
    },
    set_googleLanguages (state, googleLanguages) {
      state.googleLanguages = googleLanguages;
    },
    set_adwordsEntitiesResponse (state, adwordsEntitiesResponse) {
      state.adwordsEntitiesResponse = adwordsEntitiesResponse;
    },
    set_isPromotionMode (state, isPromoMode) {
      state.isPromotionMode = isPromoMode;
    },
    set_promotionId (state, id) {
      state.promotionId = id;
    },
    set_promotionObject (state, promotionObject) {
      state.promotionObject = promotionObject;
    },
    set_products (state, products) {
      state.products = products;
    },
    set_mediaPlans (state, mediaPlans) {
      state.mediaPlans = mediaPlans;
    },
    set_amsAccounts (state, amsAccounts) {
      state.amsAccounts = amsAccounts;
    },
    set_savedTargets (state, savedTargets) {
      state.savedTargets = savedTargets;
    },
    set_amsEntitiesResponse (state, amsEntitiesResponse) {
      state.amsEntitiesResponse = amsEntitiesResponse;
    },
    set_AmsPublishedData (state, value) {
      state.amsPublishedData = value;
    },
    set_linkedInEntitiesResponse (state, data) {
      state.linkedInEntitiesResponse = data;
    },
    set_twitterEntitiesResponse (state, data) {
      state.twitterEntitiesResponse = data;
    },
    set_isPromotionCampaignLaunch (state, isPromotionCampaignLaunch) {
      state.isPromotionCampaignLaunch = isPromotionCampaignLaunch;
    },
    set_selectedMediaPlan (state, selectedMediaPlan) {
      state.selectedMediaPlan = selectedMediaPlan;
    },
    set_bpCurrencySymbol (state, bpCurrencySymbol) {
      state.bpCurrencySymbol = bpCurrencySymbol;
    },
    set_dbmAccounts (state, dbmAccounts) {
      state.dbmAccounts = dbmAccounts;
    },
    set_dv360EntitiesResponse (state, dv360EntitiesResponse) {
      state.dv360EntitiesResponse = dv360EntitiesResponse;
    },
    set_imagesData (state, imagesData) {
      state.imagesData = imagesData
    },
    set_videosData (state, videosData) {
      state.videosData = videosData
    },
    set_showAdSheetLoader (state, flag) {
      state.showAdSheetLoader = flag
    },
    set_accountMacros (state, accountMacros) {
      state.accountMacros = accountMacros
    },
    set_twitterAccounts (state, value) {
      state.twitterAccounts = value;
    },
    set_autoNavigateToNextStep (state, value) {
      state.autoNavigateToNextStep = value;
    },
    set_summaryLocation (state, value) {
      state.summaryLocation = value;
    },
    set_tableData (state, value) {
      state.tableData = value;
    },
    set_keywordTableData (state, value) {
      state.keywordTablesData = value;
    },
    set_productTableData (state, value) {
      state.productTablesData = value;
    },
    set_mediaPlanConfigs (state, value) {
      state.mediaPlanConfigs = value;
    },
    set_selectedMediaplanName (state, value) {
      state.selectedMediaplanName = value;
    },
    set_configChangeLoading (state, value) {
      state.configChangeLoading = value;
    },
    set_connectedInstagramAccountId (state, value) {
      state.connectedInstagramAccountId = value;
    },
    set_linkedInAccounts (state, linkedInAccounts) {
      state.linkedInAccounts = linkedInAccounts;
    },
    set_selectedObjectiveIdForPublisher (state, value) {
      state.selectedObjectiveIdForPublisher = value;
    },
    set_recipeType (state, value) {
      state.recipeType = value;
    },
    set_currentAdSheet (state, value) {
      state.currentAdSheet = value
    },
    set_linkedInLeadForms (state, value) {
      state.linkedInLeadForms = value;
    },
    set_tempMappedLocations (state, value) {
      state.tempMappedLocations = value;
    },
    set_savedMappedLocations (state, value) {
      state.savedMappedLocations = value;
    },
    set_mappedLocationsChanged (state, value) {
      state.mappedLocationsChanged = value;
    },
    set_dbLocations (state, value) {
      state.dbLocations = value;
    },
    set_dbStores (state, value) {
      state.dbStores = value;
    },
    set_dbLabelTypes (state, value) {
      state.dbLabelTypes = value;
    },
    set_isCampaignLauncherVerificationEnabled (state, value) {
      state.isCampaignLauncherVerificationEnabled = value;
    },
    set_placementCustomizationSettings (state, value) {
      state.placementCustomizationSettings = value;
    },
    set_dynamicCreativeSettings (state, value) {
      state.dynamicCreativeSettings = value
    },
    set_businessProfile (state, value) {
      state.businessProfile = value;
    },
    set_existingTweets (state, value) {
      state.existingTweets = value;
    },
    set_previewableRowsCreativeSpec (state, value) {
      state.previewableRowsCreativeSpec = value;
    },
    set_nomenclatureSettings (state, value) {
      state.nomenclatureSettings = value;
    },
    set_isLastPublishSuccessful (state, value) {
      state.isLastPublishSuccessful = value
    },
    set_publishJson (state, value) {
      state.publishJson = value;
    },
    set_publishedCreativesInitialState (state, value) {
      state.publishedCreativesInitialState = value;
    },
    set_hotInitalState (state, value) {
      state.hotInitalState = value;
    },
    set_editSheetLoadProgress (state, value) {
      state.editSheetLoadProgress = value;
    },
    set_currentVersionOfGeneratedEntities (state, value) {
      state.currentVersionOfGeneratedEntities = value;
    },
    set_adLabelDimensionNomenclature (state, value) {
      state.adLabelDimensionNomenclature = value;
    },
    set_labelDimensions (state, value) {
      state.labelDimensions = value;
    },
    set_lineItemLabels (state, value) {
      state.lineItemLabels = value;
    },
    setInitialLocations (state, value) {
      state.initialLocations = value;
    },
    setAdwordsLocations (state, value) {
      state.adwordsLocations = value;
    },
    setAdwordsTargets (state, value) {
      state.adwordsTargets = value;
    },
    setAdGroupCampaignMapper (state, value) {
      state.adGroupCampaignMapper = value;
    },
    setAdGroupMapper (state, value) {
      state.adGroupNameToIdentifierMapper = value;
    },
    set_macroValueMapper (state, value) {
      state.macroValueMapper = value;
    },
    setNegativeKeywords (state, value) {
      state.negativeKeywords = value;
    },
    setEditedSummaryData (state, value) {
      state.editedSummaryData = value;
    },
    set_adsData (state, value) {
      state.adgroupDetails = value;
    },
    set_sdAdsData (state, value) {
      state.adsData = value;
    },
    set_amsSheetData (state, value) {
      state.amsSheetDetails = value;
    },
    set_allCreativesInfo (state, value) {
      state.allCreativesInfo = value;
    },
    set_createSheetCreatives (state, value) {
      state.createSheetCreatives = value;
    },
    set_editSheetCreatives (state, value) {
      state.editSheetCreatives = value;
    },
    set_publishedSheetCreatives (state, value) {
      state.publishedSheetCreatives = value;
    },
    set_totalSheetCreatives (state, value) {
      state.totalSheetCreatives = value;
    },
    set_adCreativeMapping (state, value) {
      state.adCreativeMapping = value;
    },
    setAdsetBudgetError (state, value) {
      state.adsetBudgetError = value;
    },
    setChangedData (state, { value, campaignId }) {
      state.changedData[campaignId] = value;
    },
    set_adWordsAdSheetSettings (state, value) {
      state.adwordsAdSheetSettingsInfo = value;
    },
    set_adSheetCustomizationSettings (state, value) {
      state.adSheetCustomizationSettings = value;
    },
    set_facebookLanguages (state, value) {
      state.facebookLanguages = value;
    },
    set_isPostsFetched (state, value) {
      state.isPostsFetched = value;
    },
    set_facebookPosts (state, value) {
      state.facebookPosts = value;
    },
    set_instagramPosts (state, value) {
      state.instagramPosts = value;
    },
    set_fetchingProductsetsFailed (state, { lineItemId, value }) {
      state.fetchingProductsetsFailed[lineItemId] = value;
    },
    set_conversionAction (state, value) {
      state.conversionAction = value;
    },
    set_isWizardFlow (state, value) {
      state.isWizardFlow = value;
    },
    setPublishedEditedSummaryData (state, value) {
      state.publishedEditedSummaryData = value;
    },
    resetState (state) {
      state = Object.assign(state, initialState());
    },
    set_mediaResponse (state, value) {
      state.mediaResponse = value;
    },
    set_promoAlertMessages (state, promoAlertMessages) {
      state.promoAlertMessages = promoAlertMessages;
    },
    set_bulkApplyCalledForLineItemId (state, value) {
      state.bulkApplyCalledForLineItemId = value;
    },
    set_allStoresAlreadyFetched (state, value) {
      state.allStoresAlreadyFetched = value;
    },
    set_selectedBpseId (state, bpseId) {
      state.selectedBpseId = bpseId;
    },
    setFacebookTargetMacroName (state, value) {
      if (value) {
        state.facebookTargetMacroName = value;
      } else {
        state.facebookTargetMacroName = "Target-{Age}-{G}-{Location}";
      }
    },
    set_facebookTargetLocationData (state, { data, index }) {
      Object.values(locationCategories).forEach(category => {
        state.facebookTargetLocationData[index].geoLocations[category] = [];
      });

      data.forEach(location => {
        let category = locationCategories[location.type];
        // console.log(category);
        if (category) {
          // state.facebookTargetLocationData[index].geoLocations[category].push(location);
          let targetIndex = state.facebookTargetLocationData[index].excludedGeoLocations[category].findIndex(item => item.key === location.key);
          if (targetIndex === -1) {
            state.facebookTargetLocationData[index].geoLocations[category].push(location);
          }
        }
      });
      // remove from excluded location after deselected from dropdown
      Object.values(locationCategories).forEach(category => {
        let excludedLocations = state.facebookTargetLocationData[index].excludedGeoLocations[category];
        for (let i = excludedLocations.length - 1; i >= 0; i--) {
          let location = excludedLocations[i];
          let targetIndex = data.findIndex(item => item.key === location.key);
          if (targetIndex === -1) {
            excludedLocations.splice(i, 1);
          }
        }
      });
    },
    remove_facebookTargetLocationData (state, { data, index }) {
      let locationType = locationCategories[data.type];
      // console.log(locationType);
      if (locationType) {
        let geoLocationIndex = state.facebookTargetLocationData[index].geoLocations[locationType].findIndex(location => location.key === data.key);
        if (geoLocationIndex !== -1) {
          state.facebookTargetLocationData[index].geoLocations[locationType].splice(geoLocationIndex, 1);
        }
        let excludedGeoLocationIndex = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].findIndex(location => location.key === data.key);
        if (excludedGeoLocationIndex !== -1) {
          state.facebookTargetLocationData[index].excludedGeoLocations[locationType].splice(excludedGeoLocationIndex, 1);
        }
      }
    },
    set_excludeLocationData (state, { data, index }) {
      let locationType = locationCategories[data.type];
      if (locationType) {
        let targetIndex = state.facebookTargetLocationData[index].geoLocations[locationType].findIndex(location => location.key === data.key);
        if (targetIndex > -1) {
          state.facebookTargetLocationData[index].geoLocations[locationType].splice(targetIndex, 1);
          // Check if the key already exists in excludedGeoLocations
          let existsInExcludedGeoLocations = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].some(location => location.key === data.key);
          if (!existsInExcludedGeoLocations) {
            state.facebookTargetLocationData[index].excludedGeoLocations[locationType].push(data);
          }
        }
      }
    },
    set_includeLocationData (state, { data, index }) {
      let locationType = locationCategories[data.type];
      if (locationType) {
        let targetIndex = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].findIndex(location => location.key === data.key);
        if (targetIndex > -1) {
          state.facebookTargetLocationData[index].excludedGeoLocations[locationType].splice(targetIndex, 1);
          let existsInGeoLocations = state.facebookTargetLocationData[index].geoLocations[locationType].some(location => location.key === data.key);
          if (!existsInGeoLocations) {
            state.facebookTargetLocationData[index].geoLocations[locationType].push(data);
          }
        }
      }
    },
    set_includeLocationDataFromTargetString (state, { data, index }) {
      state.facebookTargetLocationData[index].geoLocations = data;
    },
    set_excludeLocationDataFromTargetString (state, { data, index }) {
      state.facebookTargetLocationData[index].excludedGeoLocations = data;
    },
    set_radiusLocation (state, { data, radius, isExcluded, index }) {
      let locationType = "";
      if (locationCategories[data.type] === locationCategories.city) {
        locationType = locationCategories.city;
      } else if (locationCategories[data.type] === locationCategories.place) {
        locationType = locationCategories.place;
      } else {
        locationType = locationCategories.custom_location;
      }
      if (radius !== undefined) {
        if (isExcluded !== undefined && isExcluded === true) {
          state.facebookTargetLocationData[index].excludedGeoLocations[locationType] = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].map(item => {
            if (item.key === data.key) {
              return {
                ...item,
                "radius": radius,
                "distance_unit": "mile"
              };
            } else {
              return item;
            }
          });
        } else {
          state.facebookTargetLocationData[index].geoLocations[locationType] = state.facebookTargetLocationData[index].geoLocations[locationType].map(item => {
            if (item.key === data.key) {
              return {
                ...item,
                "radius": radius,
                "distance_unit": "mile"
              };
            } else {
              return item;
            }
          });
        }
      } else {
        if (isExcluded !== undefined && isExcluded === true) {
          state.facebookTargetLocationData[index].excludedGeoLocations[locationType] = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].map(item => {
            if (item.key === data.key) {
              delete item.radius;
              delete item.distance_unit;
              return item;
            } else {
              return item;
            }
          });
        } else {
          state.facebookTargetLocationData[index].geoLocations[locationType] = state.facebookTargetLocationData[index].geoLocations[locationType].map(item => {
            if (item.key === data.key) {
              delete item.radius;
              delete item.distance_unit;
              return item;
            } else {
              return item;
            }
          });
        }
      }
    },
    add_facebookTargetCreationData (state, { addType }) {
      if (addType === 'location') {
        let id = Math.random()
        let geoLocations = {};
        let excludedGeoLocations = {};
        Object.values(locationCategories).forEach(category => {
          geoLocations[category] = [];
          excludedGeoLocations[category] = [];
        });
        state.facebookTargetLocationData.push({
          "locationId": id,
          geoLocations,
          excludedGeoLocations,
          "location_types": ["recent", "home"]
        });
      } else if (addType === 'flexibleSpec') {
        let id = Math.random()
        state.facebookFlexibleSpecData.push({
          "flexId": id,
          "gender": FacebookFlexibleSpecConst.gender,
          "minAge": FacebookFlexibleSpecConst.minAge,
          "maxAge": FacebookFlexibleSpecConst.maxAge,
          "locales": FacebookFlexibleSpecConst.locales,
          "relationship_statuses": FacebookFlexibleSpecConst.relationship_statuses,
          "flexible_spec": FacebookFlexibleSpecConst.flexible_spec,
          "exclusions": FacebookFlexibleSpecConst.exclusions,
          "custom_audiences": FacebookFlexibleSpecConst.custom_audiences,
          "excluded_custom_audiences": FacebookFlexibleSpecConst.excluded_custom_audiences
        });
      }
    },
    copy_facebookTargetCreationData (state, { copyType, index }) {
      let id = Math.random()
      if (copyType === 'location') {
        let geoLocations = {};
        let excludedGeoLocations = {};
        Object.values(locationCategories).forEach(category => {
          if (state.facebookTargetLocationData[index].geoLocations[category]) {
            geoLocations[category] = JSON.parse(JSON.stringify(state.facebookTargetLocationData[index].geoLocations[category]));
          }
          if (state.facebookTargetLocationData[index].excludedGeoLocations[category]) {
            excludedGeoLocations[category] = JSON.parse(JSON.stringify(state.facebookTargetLocationData[index].excludedGeoLocations[category]));
          }
        });
        state.facebookTargetLocationData.push({
          "locationId": id,
          geoLocations,
          excludedGeoLocations,
          "location_types": state.facebookTargetLocationData[index].location_types
        });
      } else if (copyType === 'flexibleSpec') {
        let flexId = Math.random()
        state.facebookFlexibleSpecData.push({
          "flexId": flexId,
          "minAge": state.facebookFlexibleSpecData[index].minAge,
          "maxAge": state.facebookFlexibleSpecData[index].maxAge,
          "gender": [...state.facebookFlexibleSpecData[index].gender],
          "locales": [...state.facebookFlexibleSpecData[index].locales],
          "relationship_statuses": [...state.facebookFlexibleSpecData[index].relationship_statuses],
          "flexible_spec": JSON.parse(JSON.stringify([...state.facebookFlexibleSpecData[index].flexible_spec])),
          "exclusions": JSON.parse(JSON.stringify({ ...state.facebookFlexibleSpecData[index].exclusions })),
          "custom_audiences": [...state.facebookFlexibleSpecData[index].custom_audiences],
          "excluded_custom_audiences": [...state.facebookFlexibleSpecData[index].excluded_custom_audiences]
        });
      }
    },
    delete_facebookTargetCreationData (state, { deleteType, index }) {
      // console.log(index);
      if (deleteType === 'location') {
        if (state.facebookTargetLocationData.length === 1) return;
        state.facebookTargetLocationData.splice(index, 1);
      } else if (deleteType === 'flexibleSpec') {
        // flexible spec part here
        if (state.facebookFlexibleSpecData.length === 1) return;
        state.facebookFlexibleSpecData.splice(index, 1);
      }
    },
    set_AgeMin (state, { data, index }) {
      state.facebookFlexibleSpecData[index].minAge = data;
    },
    set_AgeMax (state, { data, index }) {
      state.facebookFlexibleSpecData[index].maxAge = data;
    },
    set_Gender (state, { data, index }) {
      state.facebookFlexibleSpecData[index].gender = data;
    },
    set_Languages (state, { data, index }) {
      state.facebookFlexibleSpecData[index].locales = data;
    },
    set_RelationshipStatuses (state, { data, index }) {
      state.facebookFlexibleSpecData[index].relationship_statuses = data;
    },
    set_IncludedCustomAudience (state, { data, index }) {
      state.facebookFlexibleSpecData[index].custom_audiences = data;
    },
    set_ExcludedCustomAudience (state, { data, index }) {
      state.facebookFlexibleSpecData[index].excluded_custom_audiences = data;
    },
    set_FlexibleSpec (state, { data, index, narrowIndex }) {
      state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex] = {}
      data.forEach((target) => {
        var type = target.type;
        if (state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][type]) {
          state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][type].push({ id: target.id, name: target.name, type: target.type, audience_size_lower_bound: target.audience_size_lower_bound, audience_size_upper_bound: target.audience_size_upper_bound, path: target.path });
        } else {
          state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][type] = [{ id: target.id, name: target.name, type: target.type, audience_size_lower_bound: target.audience_size_lower_bound, audience_size_upper_bound: target.audience_size_upper_bound, path: target.path }];
        }
      });
    },
    set_FlexibleSpecFromTargetString (state, { data, index }) {
      state.facebookFlexibleSpecData[index].flexible_spec = data;
    },
    set_ExclusionsFromTargetString (state, { data, index }) {
      state.facebookFlexibleSpecData[index].exclusions = data;
    },
    set_Exclusions (state, { data, index }) {
      state.facebookFlexibleSpecData[index].exclusions = {};
      data.forEach((target) => {
        var type = target.type;
        if (state.facebookFlexibleSpecData[index].exclusions[type]) {
          state.facebookFlexibleSpecData[index].exclusions[type].push({ id: target.id, name: target.name, type: target.type, audience_size_lower_bound: target.audience_size_lower_bound, audience_size_upper_bound: target.audience_size_upper_bound, path: target.path });
        } else {
          state.facebookFlexibleSpecData[index].exclusions[type] = [{ id: target.id, name: target.name, type: target.type, audience_size_lower_bound: target.audience_size_lower_bound, audience_size_upper_bound: target.audience_size_upper_bound, path: target.path }];
        }
      });
    },
    remove_TargetFromFlexibleSpec (state, { data, index, narrowIndex }) {
      if (state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][data.type]) {
        let targetIndex = state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][data.type].findIndex(target => target.id === data.id);
        if (targetIndex > -1) {
          state.facebookFlexibleSpecData[index].flexible_spec[narrowIndex][data.type].splice(targetIndex, 1);
        }
      }
    },
    remove_TargetFromExclusion (state, { data, index }) {
      if (state.facebookFlexibleSpecData[index].exclusions[data.type]) {
        let targetIndex = state.facebookFlexibleSpecData[index].exclusions[data.type].findIndex(target => target.id === data.id);
        if (targetIndex > -1) {
          state.facebookFlexibleSpecData[index].exclusions[data.type].splice(targetIndex, 1);
        }
      }
    },
    update_TargetName (state, { targetName, index }) {
      state.facebookTargetCreationData[index].targetName = targetName;
    },
    set_FavouriteTarget (state, { index }) {
      state.facebookTargetCreationData[index].isFavourite = !state.facebookTargetCreationData[index].isFavourite;
    },
    delete_Targets (state, { index }) {
      if (index !== undefined) {
        state.facebookTargetCreationData.splice(index, 1);
      } else {
        state.facebookTargetCreationData = [];
      }
    },
    set_facebookTempTargetName (state, targetName) {
      state.facebookTargetNameTemp = targetName;
    },
    set_savedTargetId (state, { targetId, index }) {
      state.facebookTargetCreationData[index].savedTargetId = targetId;
    },
    reset_facebookTargetCreationData (state) {
      state.facebookTargetMacroName = "Target-{Age}-{Gender}-{Location}";
      state.facebookTargetNameTemp = "Target-13-NoMax-All-IN";
      state.extraPropertiesInTargets = {};

      let geoLocations = {};
      let excludedGeoLocations = {};
      Object.values(locationCategories).forEach(category => {
        geoLocations[category] = [];
        excludedGeoLocations[category] = [];
      });

      geoLocations['countries'].push({
        "name": "India",
        "type": "country",
        "country_code": "IN",
        "country_name": "India",
        "supports_region": true,
        "supports_city": true,
        "key": "IN",
        "is_excluded": false
      });

      state.facebookTargetCreationData = [
        {
          "savedTargetId": null,
          "targetName": "Target-13-NoMax-All-IN",
          geoLocations,
          excludedGeoLocations,
          "location_types": ['recent', 'home'],
          "gender": [],
          "locales": [],
          "relationship_statuses": [],
          "minAge": 18,
          "maxAge": "No Max",
          "flexible_spec": [{}],
          "exclusions": {},
          "isFavourite": false,
          "estimatedReach": 0,
          "custom_audiences": [],
          "excluded_custom_audiences": []
        }
      ];

      state.facebookTargetLocationData = [
        {
          "locationId": Math.random(),
          geoLocations: { ...geoLocations },
          excludedGeoLocations: { ...excludedGeoLocations },
          "location_types": ['recent', 'home']
        }
      ];

      state.facebookFlexibleSpecData = [
        {
          "flexId": Math.random(),
          "gender": [1, 2],
          "minAge": 18,
          "maxAge": "No Max",
          "locales": [],
          "relationship_statuses": [],
          "flexible_spec": [{}],
          "exclusions": {},
          "custom_audiences": [],
          "excluded_custom_audiences": []
        }
      ];

      state.facebookTargetLoading = [false];
    },
    set_locationType (state, { locationType, index }) {
      state.facebookTargetLocationData[index].location_types = locationType;
    },
    remove_ExcludedLocationAfterOverride (state, { index, item }) {
      let locationType = locationCategories[item.type];
      if (locationType) {
        let targetIndex = state.facebookTargetLocationData[index].excludedGeoLocations[locationType].findIndex(location => location.key === item.key);
        if (targetIndex > -1) {
          state.facebookTargetLocationData[index].excludedGeoLocations[locationType].splice(targetIndex, 1);
        }
      }
    },
    set_molocoAccounts (state, value) {
      state.molocoAccounts = value;
    },
    set_molocoCampaignLauncherPublishDataId (state, value) {
      state.molocoCampaignLauncherPublishDataId = value
    },
    set_publishInProgress (state, value) {
      state.publishInProgress = value
    },
    set_renderSubmissionSummary (state, value) {
      state.renderSubmissionSummary = value
    },
    set_molocoTargetingKeyValuePairs (state, value) {
      state.molocoTargetingKeyValuePairs = value
    },
    set_LocationAndDetailTargeting (state) {
      state.facebookFlexibleSpecData.splice(1);
      state.facebookTargetLocationData.splice(1);
      state.facebookTargetCreationData.splice(1);
    },
    set_molocoBrandApps (state, { productId, value }) {
      state.molocoBrandApps[productId] = value;
    },
    set_molocoTrackingLinks (state, { productId, value }) {
      state.molocoTrackingLinks[productId] = value;
    },
    set_extraPropertiesFromTargets (state, value) {
      state.extraPropertiesInTargets = value;
    },
    set_savedTargetsByBpseIdMapper (state, { bpseId, savedTargets }) {
      let tempTargets = { ...state.savedTargetsByBpseIdMapper }
      tempTargets[bpseId] = savedTargets
      state.savedTargetsByBpseIdMapper = tempTargets;
    },
    set_isSummaryDataFetchComplete (state, value) {
      state.isSummaryDataFetchComplete = value;
    },
    set_uploadedInstagramVideos (state, instagramVideoId) {
      if (state.uploadedInstagramVideos.includes(instagramVideoId)) return;
      state.uploadedInstagramVideos.push(instagramVideoId);
    },
    set_globalLabel (state, value) {
      state.globalLabels = value;
    },
    add_globalLabel (state, value) {
      state.globalLabels.push(value);
    },
    remove_globalLabel (state, id) {
      let labelIndex = state.globalLabels.findIndex((label) => label.id == id);
      if (labelIndex > -1) {
        state.globalLabels.splice(labelIndex, 1);
      }
    },
    reset_globalLabel (state) {
      state.globalLabels = [];
    },
    set_adExtensions (state, value) {
      state.allAdExtensions = value;
    },
    set_isSummaryDataSet (state, value) {
      state.isSummaryDataSet = value
    },
    set_objectives (state, value) {
      state.objectives = value;
    },
    set_keyMetrics (state, value) {
      state.keyMetrics = value;
    },
    set_editedCampaignBid (state, value) {
      state.editedCampaignBid = value;
    },
    set_landingPageSettings (state, data) {
      state.landingPageSettings = data;
    },
    set_landingPages (state, data) {
      state.landingPages = data;
    },
    set_selectedPromotionLauncherConfig (state, selectedPromotionLauncherConfig) {
      state.selectedPromotionLauncherConfig = selectedPromotionLauncherConfig;
    },
    set_adSheetSettingsOpenModel (state, data) {
      state.adSheetSettingsOpenModel = data;
    }
  },
  getters: {
    getFacebookNomenclature (state) {
      // Facebook engineId = 5
      let facebookNomenclature = state.nomenclatureSettings.find(setting => setting.engineId == 5);
      let macros = facebookNomenclature ? JSON.parse(facebookNomenclature.settingsJson) : null;
      if (macros) {
        return {
          Campaign: macros.campaign,
          AdSet: macros.insertionOrder,
          Ad: macros.lineItemMacro,
          Creative: macros.creative,
          UrlTags: macros.editUrl
        }
      }
      return macros;
    },
    getIfSelectedConfigIsRnF (state) {
      if (state.selectedCampaignLauncherConfigId) {
        return JSON.parse(state.campaignLauncherConfigs[state.selectedCampaignLauncherConfigId].config).campaign.buyType == 4;
      } else {
        return false;
      }
    },
    businessProfileTimezone (state) {
      let bp = state.businessProfile;
      return bp ? `${bp.timeZone.displayName} (${bp.timeZone.offset > 0 ? "+" : "-"}${bp.timeZone.offset.toFixed(2).toString().replace(".", ":")})` : "";
    },
    getInstantExperienceById (state) {
      return function (id) {
        return state.instantExperiences.find(ie => ie.id == id);
      }
    },
    getIfBusinessExistsForSelectedConfigAccount (state) {
      if (state.selectedCampaignLauncherConfigId && state.selectedPublisher == 5) {
        let bpseId = JSON.parse(state.campaignLauncherConfigs[state.selectedCampaignLauncherConfigId].config).bpseId;
        return state.accountAssetsMapper[bpseId].businessId
      } else {
        return false;
      }
    },
    getBpseIdFromConfigId (state) {
      return function (configId) {
        return state.campaignLauncherConfigBpseIdMapper[configId].BpseId
      }
    },
    getBillingEventFromConfigId (state) {
      return function (id) {
        var config = JSON.parse(state.campaignLauncherConfigs[id].config);
        if (config.searchEngineTypeId === state.publisher.Facebook) {
          let billingEvent = config.adset.billingEvent;
          return billingEvent;
        }
        return false
      }
    },
    getCampaignObjectiveFromConfigId (state) {
      return function (id) {
        let billingEvent = JSON.parse(state.campaignLauncherConfigs[id].config).campaign.objective;
        return billingEvent;
      }
    },
    getMediaPlanById (state) {
      return function (id) {
        var mediaPlans = state.mediaPlans.filter(function (item, index) {
          return item.Id == id;
        });
        if (mediaPlans && mediaPlans.length > 0) {
          return mediaPlans[0];
        } else {
          return { Id: 0 };
        }
      }
    },
    getPublisherNameById (state) {
      return function (id) {
        let publishers = state.publisherOptions.filter(key => {
          return key.id == id;
        })
        let publisher = publishers.length == 1 ? publishers[0].name : "Unknown";
        return publisher;
      }
    },
    getCampaignConfigById (state) {
      return function (id) {
        var configs = state.campaignLauncherConfigurations.filter(function (item, index) {
          return item.Id == id;
        })
        if (configs && configs.length > 0) {
          return configs[0];
        } else {
          return { Id: 0 };
        }
      }
    },
    isLivePromotion (state) {
      return state.promotionObject && state.promotionObject.status == PromotionStatus.enabled
    },
    isPublisherSupportedForSubmission (state) {
      return function (id) {
        var length = state.publisherSupportedForSubmissions.filter(x => x.id === id).length
        if (length > 0) {
          return true
        }
        return false;
      }
    },
    getEngineTypeFromPublisherId (state) {
      return function (id) {
        for (const [key, value] of Object.entries(state.engineType_PublisherMapper)) {
          if (value.includes(id)) {
            return key
          }
        }
      }
    },
    isPromotionDisabled (state) {
      return state.promotionObject && state.promotionObject.status == PromotionStatus.disabled
    },
    isFullIntegration (state) {
      return function () {
        if (state.adPublishEnabledPublisherIds.includes(state.selectedPublisher) ||
          (state.deltax.isAdwordsCampaignLaucherFlow && state.selectedPublisher == state.publisher.Adwords)) {
          return true;
        }
        return false;
      }
    },
    getNomenclatureMacros (state) {
      return function (publisherId) {
        let nomenclature = state.nomenclatureSettings.find(setting => setting.engineId == publisherId);
        let macros = nomenclature ? JSON.parse(nomenclature.settingsJson) : null;
        if (macros) {
          return {
            campaign: macros.campaign,
            adSet: macros.insertionOrder,
            ad: macros.lineItemMacro,
            creative: macros.creative,
            youTubeAdGroup: macros.youTubeAdGroup,
            urlTag: macros.editUrl
          }
        }
        return macros;
      }
    },
    initialLocations (state) {
      return state.initialLocations;
    },
    getPublisherAccounts (state) {
      switch (state.selectedPublisher) {
        case state.publisher.Facebook: return state.accounts;
        case state.publisher.Adwords: return state.googleAccounts;
        case state.publisher.DV360: return state.dbmAccounts;
        case state.publisher.LinkedIn: return state.linkedInAccounts;
        case state.publisher.Twitter: return state.twitterAccounts;
        default: return state.amsAccounts;
      }
    },
    adwordsLocations (state) {
      return state.adwordsLocations;
    },
    adwordsTargets (state) {
      return state.adwordsTargets;
    },
    linkedInEntitiesResponse  (state) {
      return state.linkedInEntitiesResponse;
    },
    editedSummaryData  (state) {
      return state.editedSummaryData;
    },
    adgroupDetails (state) {
      return state.adgroupDetails
    },
    amsSheetDetails (state) {
      return state.amsSheetDetails
    },
    conversionAction: state => state.conversionAction,
    landingPageSettings: state => state.landingPageSettings,
    landingPages: state => state.landingPages
  },
  actions: {
    getConversionActions: async (context, payload) => {
      let response = await APIService.getConversionActions(payload.businessProfileId, payload.businessProfilesSearchEngineId, payload.type);
      context.commit("set_conversionAction", response);
    },
    getLabelsTypesById: async (context) => {
      let labelsArray = context.state.dbStores.map(s => s.labels);
      let labelTypeIds = []
      labelsArray.forEach((lba) => {
        lba.forEach((lb) => {
          if (!labelTypeIds.includes(lb.labelTypeId)) {
            labelTypeIds.push(lb.labelTypeId);
          }
        })
      });
      let labelDimentions = await APIService.getLabelsTypesById(labelTypeIds);
      context.commit("set_dbLabelTypes", labelDimentions);
    },
    generate_TargetString (context, targetData) {
      let flexibleTargetSpec = [];
      let flexibleExcludeTarget = {};

      for (let flexibleSpec in targetData.flexible_spec) {
        flexibleTargetSpec[flexibleSpec] = {}
        for (let field in targetData.flexible_spec[flexibleSpec]) {
          if (Array.isArray(targetData.flexible_spec[flexibleSpec][field])) {
            if (field === 'relationship_statuses' || field === 'education_statuses') {
              flexibleTargetSpec[flexibleSpec][field] = targetData.flexible_spec[flexibleSpec][field].map(obj => obj.id)
            } else {
              flexibleTargetSpec[flexibleSpec][field] = targetData.flexible_spec[flexibleSpec][field].map(obj => ({ "id": obj.id, "name": obj.name }))
            }
          }
        }
      }
      for (let item in targetData.exclusions) {
        if (Array.isArray(targetData.exclusions[item])) {
          flexibleExcludeTarget[item] = targetData.exclusions[item].map(obj => ({ "id": obj.id, "name": obj.name }))
        }
      }
      let stringData = {
        "geo_locations": {
          "countries": targetData.geoLocations.countries.map(country => country.country_code),
          "cities": targetData.geoLocations.cities.map(city => {
            let cityObj = { "key": city.key, "name": city.name };
            if (city.radius) {
              cityObj.radius = city.radius;
            }
            if (city.distance_unit) {
              cityObj.distance_unit = city.distance_unit;
            }
            return cityObj;
          }),
          "zips": targetData.geoLocations.zips.map(zip => ({ "key": zip.key })),
          "geo_markets": targetData.geoLocations.geo_markets.map(geoMarket => ({ "key": geoMarket.key, "name": geoMarket.name })),
          "regions": targetData.geoLocations.regions.map(region => ({ "key": region.key, "name": region.name })),
          "location_types": targetData.location_types,
          "neighborhoods": targetData.geoLocations.neighborhoods.map(n => ({ "key": n.key, "name": n.name })),
          "custom_locations": targetData.geoLocations.custom_locations.map(l => ({ "distance_unit": l.distance_unit, "latitude": l.latitude, "longitude": l.longitude, "radius": l.radius }))
        },
        "excluded_geo_locations": {
          "countries": targetData.excludedGeoLocations.countries ? targetData.excludedGeoLocations.countries.map(country => country.country_code) : [],
          "cities": targetData.excludedGeoLocations.cities ? targetData.excludedGeoLocations.cities.map(city => {
            let cityObj = { "key": city.key, "name": city.name };
            if (city.radius) {
              cityObj.radius = city.radius;
            }
            if (city.distance_unit) {
              cityObj.distance_unit = city.distance_unit;
            }
            return cityObj;
          }) : [],
          "zips": targetData.excludedGeoLocations.zips ? targetData.excludedGeoLocations.zips.map(zip => ({ "key": zip.key })) : [],
          "geo_markets": targetData.excludedGeoLocations.geo_markets ? targetData.excludedGeoLocations.geo_markets.map(geoMarket => ({ "key": geoMarket.key, "name": geoMarket.name })) : [],
          "regions": targetData.excludedGeoLocations.regions ? targetData.excludedGeoLocations.regions.map(region => ({ "key": region.key, "name": region.name })) : [],
          "neighborhoods": targetData.excludedGeoLocations.neighborhoods.map(n => ({ "key": n.key, "name": n.name })),
          "custom_locations": targetData.excludedGeoLocations.custom_locations.map(l => ({ "distance_unit": l.distance_unit, "latitude": l.latitude, "longitude": l.longitude, "radius": l.radius }))
        },
        "age_min": targetData.minAge,
        "genders": targetData.gender,
        "locales": targetData.locales,
        "relationship_statuses": targetData.relationship_statuses,
        "custom_audiences": targetData.custom_audiences,
        "excluded_custom_audiences": targetData.excluded_custom_audiences,
        "flexible_spec": flexibleTargetSpec,
        "exclusions": flexibleExcludeTarget,
        ...context.state.extraPropertiesInTargets
      }
      for (const item in stringData.excluded_geo_locations) {
        if (stringData.excluded_geo_locations[item].length > 0) {
          stringData.excluded_geo_locations.location_types = targetData.location_types;
          break;
        }
      }
      if (targetData.maxAge <= 65) {
        stringData.age_max = targetData.maxAge;
      }
      var targetKey = targetData.locationId.toString() + '-' + targetData.flexId.toString();
      let stringifiedData = JSON.stringify(stringData)
      if (targetKey in context.state.targetsEstimateData) {
        if (context.state.targetsEstimateData[targetKey].targetString !== stringifiedData) {
          context.state.targetsEstimateData[targetKey].targetString = stringifiedData
          context.state.targetsEstimateData[targetKey].isUpdated = true
        }
      } else {
        context.state.targetsEstimateData[targetKey] = { targetString: stringifiedData, isUpdated: true }
      }
      return stringifiedData;
    },
    async generate_EstimatedReach (context, payload) {
      let targetSpec = await context.dispatch('generate_TargetString', payload.targetData);
      var targetKey = payload.targetData.locationId.toString() + '-' + payload.targetData.flexId.toString();
      try {
        if (context.state.targetsEstimateData[targetKey].isUpdated) {
          context.state.targetsEstimateData[targetKey].isUpdated = false;
          var tempLoadingData = [...context.state.facebookTargetLoading]
          tempLoadingData[payload.index] = true
          context.state.facebookTargetLoading = tempLoadingData
          payload.targetData.isLoading = true
          targetSpec = encodeURIComponent(targetSpec);
          let data = await APIService.getEstimatedReach(context.state.selectedBpseId, 'IMPRESSIONS', targetSpec);
          context.state.targetsEstimateData[targetKey].estimateReach = data[0];
          context.state.targetsEstimateData[targetKey].isValid = true;
          return data[0];
        } else {
          if (context.state.targetsEstimateData[targetKey].isValid) {
            return context.state.targetsEstimateData[targetKey].estimateReach;
          } else {
            return { errorMessage: context.state.targetsEstimateData[targetKey].errorMessage };
          }
        }
      } catch (error) {
        context.state.targetsEstimateData[targetKey].isValid = false;
        context.state.targetsEstimateData[targetKey].errorMessage = error.response.data.message;
        return { errorMessage: error.response.data.message };
      }
    },
    generate_TargetName (context, { locationData, flexibleSpecData }) {
      let targetName = context.state.facebookTargetMacroName;
      let location = [];
      if (locationData.geoLocations.countries && locationData.geoLocations.countries.length > 0) {
        location.push(locationData.geoLocations.countries.map(country => country.country_code).join("-"));
      }
      if (locationData.geoLocations.cities && locationData.geoLocations.cities.length > 0) {
        location.push(locationData.geoLocations.cities.map(city => city.name).join("-"));
      }
      if (locationData.geoLocations.regions && locationData.geoLocations.regions.length > 0) {
        location.push(locationData.geoLocations.regions.map(region => region.name).join("-"));
      }
      if (locationData.geoLocations.geo_markets && locationData.geoLocations.geo_markets.length > 0) {
        location.push(locationData.geoLocations.geo_markets.map(geoMarket => geoMarket.name).join("-"));
      }
      if (locationData.geoLocations.neighborhoods && locationData.geoLocations.neighborhoods.length > 0) {
        location.push(locationData.geoLocations.neighborhoods.map(neighborhood => neighborhood.name).join("-"));
      }
      location = location.join("-");
      let gender = "All";
      let genderMacro = "A";
      if (flexibleSpecData.gender.includes(1) && flexibleSpecData.gender.includes(2)) {
        gender = "All";
        genderMacro = "A";
      } else if (flexibleSpecData.gender.includes(1)) {
        gender = "Male";
        genderMacro = "M"
      } else if (flexibleSpecData.gender.includes(2)) {
        gender = "Female";
        genderMacro = "F"
      }

      // Create macros object
      var macros = {
        "{Age}": flexibleSpecData.minAge + "-" + flexibleSpecData.maxAge,
        "{AgeMax}": flexibleSpecData.maxAge,
        "{AgeMin}": flexibleSpecData.minAge,
        "{Gender}": gender,
        "{G}": genderMacro,
        "{Location}": location
      };

      // Replace each macro in the string
      for (var macro in macros) {
        targetName = targetName.split(macro).join(macros[macro]);
      }
      context.commit('set_facebookTempTargetName', targetName);
    },
    set_TargetNamesInTargetData ({ dispatch, state }) {
      let targetNames = [];
      for (const targetLocation of state.facebookTargetLocationData) {
        for (const flexibleSpec of state.facebookFlexibleSpecData) {
          dispatch('generate_TargetName', { locationData: targetLocation, flexibleSpecData: flexibleSpec });
          targetNames.push(state.facebookTargetNameTemp);
        }
      }
      for (let i = 0; i < state.facebookTargetCreationData.length; i++) {
        state.facebookTargetCreationData[i].targetName = targetNames[i];
      }
    },
    async generate_TargetAudience ({ dispatch, state }) {
      if (state.facebookTargetCreationData[0]) {
        var targetId = state.facebookTargetCreationData[0].savedTargetId
      }
      state.facebookTargetCreationData = [];
      for (const targetLocation of state.facebookTargetLocationData) {
        for (const flexibleSpec of state.facebookFlexibleSpecData) {
          dispatch('generate_TargetName', { locationData: targetLocation, flexibleSpecData: flexibleSpec });
          let combinedObject = {
            "id": Math.random(),
            "targetName": state.facebookTargetNameTemp,
            ...targetLocation,
            ...flexibleSpec,
            "isFavourite": false,
            "isLoading": false
          };
          // state.facebookTargetLoading = true;
          state.facebookTargetCreationData.push(combinedObject);
        }
      }
      for (let i = 0; i < state.facebookTargetCreationData.length; i++) {
        let combinedObject = state.facebookTargetCreationData[i];
        const estimateData = await dispatch('generate_EstimatedReach', { targetData: combinedObject, index: i });
        state.facebookTargetCreationData[i].estimatedReach = estimateData && estimateData.reachEstimate ? estimateData.reachEstimate : null;
        state.facebookTargetCreationData[i].lowerBoundEstimate = estimateData && estimateData.lowerBoundEstimate ? estimateData.lowerBoundEstimate : null;
        state.facebookTargetCreationData[i].upperBoundEstimate = estimateData && estimateData.upperBoundEstimate ? estimateData.upperBoundEstimate : null;
        state.facebookTargetCreationData[i].estimatedReachErrorMessage = estimateData && estimateData.errorMessage ? estimateData.errorMessage : "";
        var tempLoadingData = [...state.facebookTargetLoading]
        tempLoadingData[i] = false
        state.facebookTargetLoading = tempLoadingData
        if (state.facebookTargetCreationData[i].estimatedReach) {
          state.facebookTargetCreationData[i].isLoading = false
        }
        if (!state.facebookTargetCreationData[i].estimatedReach && !state.facebookTargetCreationData[i].isLoading) {
          state.facebookTargetCreationData[i].isLoading = true
        }
      }
      if (targetId) {
        state.facebookTargetCreationData[0].savedTargetId = targetId
      }
    },
    setFacebookTargetMacroName (context, value) {
      context.commit('setFacebookTargetMacroName', value);
      context.dispatch("set_TargetNamesInTargetData");
    },
    set_facebookTargetLocationData (context, { data, index }) {
      context.commit("set_facebookTargetLocationData", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    remove_facebookTargetLocationData (context, { data, index }) {
      context.commit("remove_facebookTargetLocationData", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_excludeLocationData (context, { data, index }) {
      context.commit("set_excludeLocationData", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_includeLocationData (context, { data, index }) {
      context.commit("set_includeLocationData", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_radiusLocation (context, { data, radius, isExcluded, index }) {
      context.commit("set_radiusLocation", { data, radius, isExcluded, index });
      context.dispatch("generate_TargetAudience");
    },
    add_facebookTargetCreationData (context, { addType }) {
      context.commit("add_facebookTargetCreationData", { addType });
      context.dispatch("generate_TargetAudience");
    },
    copy_facebookTargetCreationData (context, { copyType, index }) {
      context.commit("copy_facebookTargetCreationData", { copyType, index });
      context.dispatch("generate_TargetAudience");
    },
    delete_facebookTargetCreationData (context, { deleteType, index }) {
      context.commit("delete_facebookTargetCreationData", { deleteType, index });
      context.dispatch("generate_TargetAudience");
      // if (context.state.facebookTargetLocationData.length > 1 || context.state.facebookFlexibleSpecData.length > 1) {
      // }
    },
    set_AgeMin (context, { data, index }) {
      context.commit("set_AgeMin", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_AgeMax (context, { data, index }) {
      context.commit("set_AgeMax", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_Gender (context, { data, index }) {
      context.commit("set_Gender", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_Languages (context, { data, index }) {
      context.commit("set_Languages", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_RelationshipStatuses (context, { data, index }) {
      context.commit("set_RelationshipStatuses", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_IncludedCustomAudience (context, { data, index }) {
      context.commit("set_IncludedCustomAudience", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_ExcludedCustomAudience (context, { data, index }) {
      context.commit("set_ExcludedCustomAudience", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_FlexibleSpec (context, { data, index, narrowIndex }) {
      context.commit("set_FlexibleSpec", { data, index, narrowIndex });
      context.dispatch("generate_TargetAudience");
    },
    set_Exclusions (context, { data, index }) {
      context.commit("set_Exclusions", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    remove_TargetFromFlexibleSpec (context, { data, index, narrowIndex }) {
      context.commit("remove_TargetFromFlexibleSpec", { data, index, narrowIndex });
      context.dispatch("generate_TargetAudience");
    },
    remove_TargetFromExclusion (context, { data, index }) {
      context.commit("remove_TargetFromExclusion", { data, index });
      context.dispatch("generate_TargetAudience");
    },
    set_locationType (context, { locationType, index }) {
      context.commit("set_locationType", { locationType, index });
      context.dispatch("generate_TargetAudience");
    },
    set_facebookTargetCreationData (context, { data, index }) {
      let name = data.name
      let minAge = data.age_min
      let maxAge = data.age_max
      let genders = data.genders
      let languages = data.locales || [];
      let relationshipStatus = data.relationship_statuses || [];
      let includeLocation = data.geo_locations
      let excludeLocation = data.excluded_geo_locations || {}
      let customAudiences = data.custom_audiences || [];
      let excludedCustomAudiences = data.excluded_custom_audiences || [];

      Object.keys(FacebookTargetLocationConst.excludedGeoLocations).forEach(key => {
        if (!excludeLocation.hasOwnProperty(key)) {
          excludeLocation[key] = [];
        }
      });
      Object.keys(FacebookTargetLocationConst.geoLocations).forEach(key => {
        if (!includeLocation.hasOwnProperty(key)) {
          includeLocation[key] = [];
        }
      });
      let detailTarget = data.flexible_spec
      let exclusion = data.exclusions;
      context.commit("set_AgeMin", { data: minAge, index });
      if (maxAge) {
        context.commit("set_AgeMax", { data: maxAge, index });
      }
      context.commit('setFacebookTargetMacroName', name);
      context.commit("set_Gender", { data: genders, index });
      context.commit("set_Languages", { data: languages, index });
      context.commit("set_RelationshipStatuses", { data: relationshipStatus, index });
      context.commit("set_includeLocationDataFromTargetString", { data: includeLocation, index });
      context.commit("set_excludeLocationDataFromTargetString", { data: excludeLocation, index });
      context.commit("set_FlexibleSpecFromTargetString", { data: detailTarget, index });
      context.commit("set_ExclusionsFromTargetString", { data: exclusion, index });
      context.commit("set_IncludedCustomAudience", { data: customAudiences, index });
      context.commit("set_ExcludedCustomAudience", { data: excludedCustomAudiences, index });
      context.commit("set_LocationAndDetailTargeting");
      context.dispatch("generate_TargetAudience");
    },
    reset_facebookTargetCreationData (context) {
      context.commit("reset_facebookTargetCreationData");
    },
    async fetchMolocoBrandAppsAndMMPTrackingLinks (context, { bpseId, adAccountId, productId }) {
      if (!context.state.molocoBrandApps[productId]) {
        try {
          const { osTypes } = molocoEnums;
          const product = await APIService.getProductById(bpseId, productId);

          const androidApp = { os: osTypes.ANDROID, ...product.data.product.brand.android_app };
          const iosApp = { os: osTypes.IOS, ...product.data.product.brand.ios_app };
          const brandApps = [androidApp, iosApp].filter(e => e.bundle_id);

          context.commit("set_molocoBrandApps", { productId, value: brandApps });
        } catch (error) {
          console.error(error);
        }
      }

      if (!context.state.molocoTrackingLinks[productId]) {
        try {
          const trackingLinks = await APIService.getTrackingLinks(bpseId, adAccountId, productId);

          context.commit("set_molocoTrackingLinks", { productId, value: trackingLinks.data.tracking_links });
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getTargetsByBpseId (context, bpseId) {
      try {
        let targetsAvailable = context.state.savedTargetsByBpseIdMapper[bpseId]
        if (!targetsAvailable) {
          var response = await APIService.getTargetsByBpse(bpseId);
          let targets = []
          response.data.forEach((target) => {
            let targetString = JSON.parse(target.targetString)
            let standardTarget = targetString.interests || targetString.behaviors
            let locationNotSupported = false;
            if (targetString.geo_locations) {
              locationNotSupported = targetString.geo_locations.country_groups || targetString.geo_locations.subcity || targetString.geo_locations.medium_geo_area || targetString.geo_locations.small_geo_area
            }
            if (!(standardTarget || locationNotSupported)) {
              targets.push(target)
            }
          })
          context.commit("set_savedTargetsByBpseIdMapper", { bpseId: bpseId, savedTargets: targets });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async uploadInstagramVideoToFacebook (context, instagramVideoId) {
      if (context.state.uploadedInstagramVideos.includes(instagramVideoId)) return;

      await APIService.uploadInstagramVideoToFacebook(context.state.businessProfilesSearchEngineId, instagramVideoId);
      context.commit("set_uploadedInstagramVideos", instagramVideoId);
    },
    add_globalLabel (context, value) {
      context.commit("add_globalLabel", value);
    },
    remove_globalLabel (context, id) {
      context.commit("remove_globalLabel", id);
    },
    reset_globalLabel (context) {
      context.commit("reset_globalLabel");
    },
    async validateLandingPages (context, { campaignLauncherPublishDataId, submissionId, statusIds, isSubmissionOnly }) {
      context.commit("set_landingPages", null);

      var body = {};

      if (submissionId) body.submissionId = submissionId;
      if (statusIds) body.statusIds = statusIds;
      if (isSubmissionOnly) body.isSubmissionOnly = isSubmissionOnly;

      var landingPages = await APIService.validateLandingPages(campaignLauncherPublishDataId, body);

      context.commit("set_landingPages", landingPages);
    }
  }
};
export const store = new Vuex.Store({
  state: { ...campaignLauncheStore.state, ...authFlowStoreTemplate.state },
  actions: { ...campaignLauncheStore.actions, ...authFlowStoreTemplate.actions },
  mutations: { ...campaignLauncheStore.mutations, ...authFlowStoreTemplate.mutations },
  getters: { ...campaignLauncheStore.getters, ...authFlowStoreTemplate.getters },
  modules: {
    Settings
  }
});
